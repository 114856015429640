@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/mixins.scss";

.apprentice-title {
  padding: 1.5rem 4px 0.5rem;
  font-size: $font_l;
  font-weight: bold;
  &-wrapper {
    @include proContainer;
  }
}

.pandadox-iframe {
  height: calc(100dvh - 160px);
}