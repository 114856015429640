@import "../../GlobalStyles/mixins.scss";
@import "../../GlobalStyles/variables.scss";

.prelogin-layout {
  width: 100%;
  height: 100dvh;
  @include flexCenter;
  flex-flow: column;
  &_container {
    @include flexCenter;
    flex-wrap: wrap;
    width: 100%;
    max-width: 550px;
  }
  &_logo {
    width: 235px;
    margin-bottom: 24px;
    img {
      width: 100%;
    }
  }
  &_card {
    width: calc(100% - 2rem);
    max-width: 550px;
    border: $form_border_color;
    border-radius: $pixel_8;
    @include flexCenter;
    flex-direction: column;
    padding: 1.5rem 2rem;
    &_heading {
      h1 {
        font-size: $pixel_22;
      }
    }
  }
}

.preLogin-footer {
  width: 100%;
  height: 50px;
  @include flexCenter;
  a {
    font-size: 12px;
    line-height: 10px;
    color: $footer_font_color;
    text-decoration: none;
    padding: 0 0.3rem;
    border-right: 1px solid;
    &:last-child {
      border-right: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}
