@import "../../GlobalStyles/mixins.scss";
@import "../../GlobalStyles/variables.scss";

.tab {
  @include flexcolumn;
  margin: 0 $pixel_24 0 0;
  cursor: pointer;
  position: relative;
  padding: 0 0 $pixel_8 0;
  border: none;
  background: none;
  &_main {
    @include flexrowacenter;
    span {
      font-size: $font_s;
      font-weight: 400;
      color: $header_text_secondary;
      padding: 0 $pixel_8;
    }
  }
  &_dropdown {
    position: absolute;
    top: 32px;
    left: 0;
    padding: $pixel_4;
    background: $tab_dropdown_background;
    box-shadow: $secondary_boxshadow;
    border-radius: $pixel_8;
    @include flexcolumn;
    width: max-content;
    z-index: 100;
    &_item {
      background: none;
      padding: $pixel_4 $pixel_12;
      text-align: left;
      span {
        background: none;
        font-weight: 400 !important;
        font-size: $font_s;
        color: $header_text_secondary;
      }
    }
    &_item.active {
      span {
        color: $header_text_primary;
        font-weight: 600 !important;
      }
    }
    &_item:hover {
      span {
        color: $header_text_primary;
        font-weight: 500 !important;
      }
    }
  }
}
.tab.active {
  border-bottom: 2px solid $header_border_active;
  span {
    font-weight: 600;
    color: $header_text_primary;
  }
}
.tab.drop {
  padding: 0 0 $pixel_8 0;
  border-bottom: 2px solid $primary_blue;
  span {
    font-weight: 600;
  }
}

.subheader {
  @include flexrow;
  padding: $pixel_12 0 0 0;
  margin: 0 $pixel_12 0 0;
}

@include mobile {
  .subheader {
    @include flexcolumn;
    padding: $pixel_12 0;
    margin: 0;
    z-index: 999;
  }
  .tab {
    margin: 0 0 $pixel_32 0;
    &_dropdown {
      margin: $pixel_12 0 0 0;
      position: relative;
      top: 0;
      width: 100%;
    }
  }
}
