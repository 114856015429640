@import "../GlobalStyles/variables.scss";
@import "../GlobalStyles/mixins.scss";

.MuiPaginationItem-root,
.MuiInputBase-root,
.MuiButton-root,
.MuiDataGrid-root {
  font-family: $font_family !important;
}

.typography {
  font-family: $font_family !important;
  font-size: $font_s !important; 
  cursor: pointer;
}

.MuiButton {
  &-root {
    line-height: 20px !important;
    text-transform: unset !important;
    font-weight: 400 !important;
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  &-contained {
    box-shadow: none !important;
    border-radius: $default_border_radius !important;
    padding: 10px 16px !important;
  }

  &-containedPrimary {
    background-color: $primary_btn_bg !important;
    color: $primary_btn_text !important;
  }

  &-textPrimary {
    color: $link_color_table !important;
    text-transform: unset !important;
    min-width: unset !important;
  }

  &-outlinedSecondary {
    border: 1px solid $secondary_btn_bg !important;
    color: $secondary_btn_text !important;
    padding: 10px !important;
    line-height: 20px !important;
    border-radius: $default_border_radius !important;
  }
}

.proInputWrapper {
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      background: $form_value_background !important;
      border-radius: $default_border_radius;
      padding-bottom: 3px;
      padding-top: 3px;
    }

    .MuiAutocomplete-tag {
      background: $autocomplete_tab_background;
      color: $autocomplete_tab_color;

      svg {
        color: $autocomplete_tab_color;
      }
    }

    .MuiAutocomplete-clearIndicator {
      color: $autocomplete_tab_color !important;
    }

    .MuiAutocomplete-popupIndicator {
      color: $autocomplete_tab_color !important;
    }
  }

  .MuiInputBase-root {
    background: $form_value_background !important;
    border-radius: $default_border_radius;
  }

  .MuiOutlinedInput {
    &-input {
      padding: 0.7rem 1rem 0.6rem;
      font-size: $form_font_size;
      color: $color_black_white;
      background: $form_value_background;
      border-radius: $default_border_radius;
    }

    &-notchedOutline {
      border: 1px solid $form_border_color;
      border-radius: $default_border_radius;

      legend {
        max-width: 100%;
        color: white !important;
        color: $form_label_color !important;
        background: $form_label_background !important;
      }
    }
  }
  .MuiSelect-select {
    padding: 0.6rem 1rem 0.5rem;
  }

  .MuiOutlinedInput-root {
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $form_focus_color;
      }
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: $form_error_color;
      }
    }
  }

  .MuiInputLabel {
    &-outlined {
      color: $form_label_color;

      &.Mui-focused {
        color: $form_focus_color;

        &.Mui-error {
          color: $form_error_color;
        }
      }
    }
  }

  .MuiInputAdornment-outlined {
    & + .MuiOutlinedInput-input {
      padding-left: 0;
    }
  }
}

.MuiInputBase-multiline {
  padding: 0 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.proCheckbox {
  .MuiFormControlLabel-label,
  .MuiFormLabel-root {
    font-size: $form_read_label_size;
    font-family: $font_family;
  }

  .Mui-checked {
    .MuiSvgIcon-root {
      fill: $checkable_input_icon;
    }
  }

  .MuiFormGroup-root {
    flex-direction: unset;
  }
}

.proInputWithMessage {
  &-content {
    display: flex;
    align-items: center;
  }

  &-input {
    width: 100%;

    &-full {
      width: 100%;
    }

    .MuiFormControlLabel-root {
      margin-right: 10px;
    }
  }

  &-info {
    height: 24px;
  }

  .MuiFormControlLabel-label {
    font-size: $form_read_label_size;
    color: $form_label_color !important;
  }
}

.MuiFileInput {
  &-TextField {
    .MuiInputAdornment-root {
      color: $file_placeholder !important;

      .MuiSvgIcon-root {
        width: 20px;
      }
    }

    .MuiOutlinedInput-root {
      font-size: $file_placeholder_size;
    }
  }

  &-placeholder {
    font-size: $file_placeholder_size;
    color: $file_placeholder !important;
    font-weight: 300;
  }
}

.custom-checkbox .MuiSvgIcon-root {
  color: $form-label-color;
  /* Change the color to red */
}

[data-theme-ui="dark"] {
  .mui-switch {
    color: $autocomplete_tab_color;
  }

  .MuiDataGrid-cell--withRenderer {
    color: $primary_white;
  }

  .MuiSwitch-track {
    background-color: white !important;
  }
  .MuiInputAdornment-root {
    color: $primary_white !important;
    svg {
      color: $primary_white;
    }
  }

  .MuiDataGrid-columnHeaders {
    background-color: $sub_header_background;
    span {
      color: $primary_white;
    }
  }

  .MuiDataGrid-columnHeaderTitle {
    color: $primary_white;
  }

  .PrivateSwitchBase-input {
    background-color: $primary_white;
  }

  .MuiSvgIcon-root {
    color: $primary_white;
  }
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall {
    color: $primary_white;
  }

  .proFsw-close {
    svg {
      color: $primary_white;
    }
  }
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon {
    color: $primary_white;
  }
}
