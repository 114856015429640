@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/mixins.scss";

.proInputWrapper-read {
  &-label {
    color: $form_label_color;
    font-size: $form_read_label_size;
    padding-left: 5px;
    transform: translate(0, -50%);
  }
  &-value {
    padding-left: 5px;
    font-size: $form_read_value_size;
    width: 100%;
    color: $form_value_color;
    margin-top: -10px;
  }
}
.proInputWrapper-read-image {
  height: 30px;
  width: 30px;
  object-fit: contain;
  object-position: left;
}
