@import "../../../GlobalStyles/mixins.scss";
@import "../../../GlobalStyles/variables.scss";

.proGrid-link {
  @include flexAcenter;
  color: $link_color_table;
  gap: 0.6rem;
  &-icon {
    @include flexCenter;
    transform: rotate(-45deg);
  }
}
