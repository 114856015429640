@import "../../../GlobalStyles/variables.scss";
@import "../../../GlobalStyles/mixins.scss";

.proForm-layout {
  &.proReportForm {
    .proReportForm-contents {
      @include proContainer;
      padding: 1.5rem 1rem 0.2rem;
      max-height: calc(100dvh - 100px);
    }
  }
}
